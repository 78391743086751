/* @font-face {
    font-family: ultra;
    src: url("../fonts/Audiowide-Regular.ttf");
} */

* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    min-height: 100vh;
}
html {
    background-color: black;
    font-family: 'Courier New', Courier, monospace;
}

button {
    font-family: 'Courier New', Courier, monospace;
}

a {
    color: blueviolet;
    text-decoration: none;
}