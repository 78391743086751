* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
}

html {
  background-color: #000;
  font-family: Courier New, Courier, monospace;
}

button {
  font-family: Courier New, Courier, monospace;
}

a {
  color: #8a2be2;
  text-decoration: none;
}
/*# sourceMappingURL=index.f61a4815.css.map */
